'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { Form } from '@edeeone/juan-core/components/form/Form';
import { FormGroup } from '@edeeone/juan-core/components/form/formGroup/FormGroup';
import { Input } from '@edeeone/juan-core/components/form/input/Input';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { getHref } from '@edeeone/string/getHref';
import { useRouter } from 'next/navigation';
import { useFormContext } from 'react-hook-form';
import { searchFormClassNames } from './searchForm.styles';
export const SearchForm = ({ showLabel, styles, placeholder, label, searchUrl, }) => {
    const { t } = useI18n('SearchForm');
    const { push } = useRouter();
    const finalStyles = searchFormClassNames(styles);
    const onSubmit = async (data) => {
        push(getHref(`${searchUrl}/${encodeURI(data?.search || '')}`));
    };
    return (_jsx(Form, { id: "SearchForm", defaultValues: {}, onSubmit: onSubmit, children: _jsx("div", { children: _jsx(FormGroup, { name: "search", showLabel: showLabel, showOptionalLabelPostfix: false, label: label, children: _jsx(Input, { design: "quaternary", placeholder: placeholder || t('placeholder'), styles: { container__custom: finalStyles.input }, beforeInput: _jsx(SvgSpriteIcon, { className: finalStyles.icon, icon: "magnifier", "aria-hidden": true }), afterInput: _jsx(SearchFormSubmitButton, {}) }) }) }) }));
};
const SearchFormSubmitButton = ({ styles, }) => {
    const { t } = useI18n('SearchForm');
    const finalStyles = searchFormClassNames(styles);
    const { watch } = useFormContext();
    const searchValue = watch('search');
    const buttonsDisabled = (searchValue?.length || 0) < 3;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { type: "submit", styles: { button__custom: finalStyles.btnSm }, disabled: buttonsDisabled, children: t('searchButton') }), _jsx(Button, { disabled: buttonsDisabled, design: "tertiary", type: "submit", iconAfter: "magnifier", iconSize: "20", title: t('searchButton'), styles: { button__custom: finalStyles.btn } })] }));
};
