'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inputClassNames } from '@edeeone/juan-core/components/form/input/input.styles';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useFormContext } from 'react-hook-form';
export const Input = ({ name, value, type = 'text', design = 'primary', styles, beforeInput, afterInput, afterInputOuter, showValidationIcons = true, valueAsNumber = false, hasError = false, onChange, onBlur, ...props }) => {
    const { register } = useFormContext();
    const finalStyles = inputClassNames(styles, design);
    const registerProps = name
        ? register(name, {
            valueAsNumber,
            value,
            onChange,
            onBlur,
        })
        : {
            onChange,
            onBlur,
        };
    return (_jsxs("div", { className: twMergeJuan(finalStyles.controlContainer, finalStyles.controlContainer__custom, !!afterInputOuter && finalStyles.controlContainer__flex), children: [_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom, props.disabled && finalStyles.container__disabled, hasError && finalStyles.container__error), children: [beforeInput, _jsx("input", { ...props, type: type, value: value == null ? undefined : value, className: twMergeJuan(finalStyles.input, finalStyles.input__custom), ...registerProps }), showValidationIcons && hasError && (_jsx(SvgSpriteIcon, { className: finalStyles.errorIco, icon: "warning", ariaHidden: true })), afterInput] }), afterInputOuter] }));
};
