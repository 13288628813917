import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const inputDefaultClassNames = /* tw */ {
    container: 'w-full relative group/input flex items-center radius-form transition-common',
    container__custom: '',
    container__disabled: '',
    container__error: '',
    controlContainer: 'control-container',
    controlContainer__flex: 'flex items-center gap-4',
    controlContainer__custom: '',
    input: 'w-full peer p-form text-form radius-form',
    input__custom: '',
    errorIco: 'ico w-6 h-6 fill-error mr-8 shrink-0',
    successIco: 'ico w-6 h-6 fill-success mr-8 shrink-0',
    // primární textový input
    primary: {
        container: 'border-form bg-white',
        container__disabled: '!border-gray-30 !focus-within:border-gray-30',
        container__error: '!border-red',
        input: 'bg-white outline-none',
    },
    // textový input bez rámečku s podbarvením
    secondary: {
        container: 'border-none',
        input: 'bg-gray-10 outline-none',
    },
    // textový input bez rámečku (vyhledávání ve značkách)
    tertiary: {
        container: 'h-full border-none',
        input: 'h-full outline-none text-center appearance-none',
    },
    // textový input s podbarvením (vyhledávání v záhlaví, ve stránce)
    quaternary: {
        container: 'border border-gray-10 bg-gray-10',
        input: 'bg-gray-10 outline-none !px-0',
    },
    // select quantity input
    quinary: {
        container: 'h-full border-none',
        input: 'h-full outline-none text-center appearance-none hover:appearance-none px-5',
    },
};
export function inputClassNames(...args) {
    return getMemoizedFinalClassnames('inputClassNames', inputDefaultClassNames, ...args);
}
